import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useConfig } from '@elparking/components'
import Routes from 'commons/js/routes/routes'
import { LoginForm, LoginModal } from '@elparking/login'
import SocialButtons from 'commons/js/components/SocialButtons'
import * as api from 'commons/js/api'
import Logo from 'commons/js/ui/Logo'
import { getConstant, getFlag } from 'commons/js/constants'
import RegisterForm from 'commons/js/ui/RegisterForm'
import ConfirmedRegisterForm from 'commons/js/ui/ConfirmedRegisterForm'
import CountryForm from 'commons/js/ui/CountryForm/CountryForm'
import KeycloakLogin from 'commons/js/components/KeycloakLogin'
import { trackLoginSuccessEvent, trackSignupEvent } from 'commons/js/services/analytics'
import { navigateTo, reload } from 'commons/js/services/navigate'
import UrlTo from 'commons/js/util/UrlTo'

const Modal = (props) => {
  const { keycloak, flavor } = useConfig()

  const application = useConfig('tradename').trim().toLowerCase()
  const isUserServiceRegisterEnabled = getFlag('isUserServiceRegisterEnabled')
  const isRegistrationConfirmEnabled = (application !== 'elparking') && getFlag('isRegistrationConfirmEnabled')
  const webRegistrationAvailable = (application === 'elparking') || isRegistrationConfirmEnabled
  const isKeycloakEnabled = getFlag('isKeycloakEnable')

  const [modalKey, setModalKey] = useState('login')
  const [registerSuccessMessage, setRegisterSuccessMessage] = useState(props.registerSuccessMessage)
  const [initialView, setInitialView] = useState(webRegistrationAvailable ? props.initialView : 'login')

  useEffect(() => {
    setRegisterSuccessMessage(props.registerSuccessMessage)
  }, [props.registerSuccessMessage])

  const onRegisterSuccess = () => {
    if (isKeycloakEnabled) {
      setInitialView('login')
      setModalKey('loginWithMessage' + Date.now())
      setRegisterSuccessMessage(true)
    } else if (isRegistrationConfirmEnabled) {
      setInitialView('login')
      setModalKey('loginWithMessage' + Date.now())
    } else {
      props.redirect ? navigateTo(props.redirect) : reload()
    }
  }

  return <LoginModal
    key={modalKey}
    isOpen
    theme={flavor}
    showCloseButton={false}
    logo={
        <a href={Routes.homePath()}>
            <Logo height='32px' />
        </a>
    }
    {...props}
    onClose={() => {
      props.onClose && props.onClose()
      setRegisterSuccessMessage(false)
    }}
    isUserServiceRegisterEnabled={isUserServiceRegisterEnabled}
    views={webRegistrationAvailable ? ['login', 'register'] : ['login']}
    trackLoginSuccessEvent={trackLoginSuccessEvent}
    trackSignupEvent={trackSignupEvent}
    userHomePath={Routes.loginPath()}
    homePath={Routes.homePath()}
    requestPasswordPath={Routes.requestPasswordPath()}
    termsAndConditionsPath={Routes.termsAndConditionsPath}
    countryFormElement={CountryForm}
    application={getConstant('TRADENAME').trim().toLowerCase()}
    keycloakConfig={keycloak}
    loginFormComponent={keycloak ? KeycloakLogin : LoginForm}
    registerFormComponent={isRegistrationConfirmEnabled ? ConfirmedRegisterForm : RegisterForm}
    login={api.login}
    register={isRegistrationConfirmEnabled ? api.userServiceRegister : api.createAccount}
    getUser={api.getUser}
    onRegisterSuccess={onRegisterSuccess}
    registerSuccessMessage={registerSuccessMessage}
    socialButtons={webRegistrationAvailable ? <SocialButtons
      hrefFacebook={UrlTo.get('user/account/request-password-reset-for-social-network', { socialNetwork: 'facebook' })}
      hrefGoogle={UrlTo.get('user/account/request-password-reset-for-social-network', { socialNetwork: 'google' })}
    /> : null}
    initialView={initialView} />
}

Modal.defaultProps = {
  registerSuccessMessage: false,
}
Modal.propTypes = {
  keycloakConfig: PropTypes.any,
  redirect: PropTypes.string,
  initialView: PropTypes.string,
  registerSuccessMessage: PropTypes.bool,
  onClose: PropTypes.func,
}

export default Modal
