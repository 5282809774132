import React from 'react'
import PropTypes from 'prop-types'
import { Box, Copy, Image } from '@elparking/components'
import { FormattedMessage } from 'react-intl'

const ContinueMessage = ({
    onClick,
    email,
}) => {
    return <Box mb='16px'>
        <Copy padding='0' size='xxLarge' fontWeight='medium'>
            <FormattedMessage
              defaultMessage='Necesitamos verificar tu correo'
            />
        </Copy>
        <Copy padding='0.5em 0 1em 0' size='medium' fontWeight='regular'>
            <FormattedMessage
              defaultMessage='Te hemos enviado un correo electrónico a {email}'
              values={{ email: <strong>{email}</strong> }}
            />
        </Copy>
        <Copy padding='0.5em 0 1em 0' size='medium' fontWeight='regular'>
            <FormattedMessage
              defaultMessage='Para continuar con el registro, solo tienes que pulsar el enlace que encontrarás en el correo'
            />
        </Copy>
        <Box textAlign='center' mt='1me' mb='1em' w='100%'>
            <Image src='/images/userService/girl_email.svg' alt='mail' />
        </Box>
        <Copy padding='3em 0 1em 0' fontSize='small' textAlign='center' fontWeight='regular'>
            <FormattedMessage
              defaultMessage='¿No te ha llegado?'
            /> <Copy as='a' fontColor='main' dataTest='resend-confirm-button' fontSize='inherit' href='#' onClick={onClick}>
                <FormattedMessage defaultMessage='Enviar de nuevo' />
            </Copy>
        </Copy>
    </Box>
}

ContinueMessage.propTypes = {
    onClick: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
}

export default ContinueMessage
