import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from '@elparking/components'
import Routes from 'commons/js/routes/routes'
import Logo from 'commons/js/ui/Logo'
import IntentForm from './IntentForm'
import DataForm from './DataForm'

export const EVENT_KEY = 'user-service-token'

export function getMainDomain (domain) {
    try {
        return domain.match(/^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/)[1]
    } catch (err) {
        return domain
    }
}

const RegisterForm = ({
    addEventListener,
    onSubmit,
    ...restProps
}) => {
    const [token, setToken] = useState(null)
    const [email, setEmail] = useState(null)

    const listen = (event) => {
        event.stopPropagation()
        event.preventDefault()
        switch (event.data.eventId) {
            case EVENT_KEY:
                const value = event.data.token
                if (value) {
                    setToken(value)
                }
        }
    }

    addEventListener('message', listen)
    const communicationFrameUrl = 'https://' + getMainDomain(document.location.origin) + '/user-service/communication-frame'

    return <Box w='100%'>
        <iframe
          name={document.location.origin}
          loading='lazy'
          height='0px'
          scrolling='no'
          src={communicationFrameUrl} />
        <Flex w='100%' p='10px 0 5px 0' justifyContent='center' mb='35px'>
            <a href={Routes.homePath()}>
                <Logo height='30px' />
            </a>
        </Flex>
        <Box>
            {(token && email)
                ? <DataForm {...restProps } onSubmit={(values) => onSubmit({
                    ...values,
                    email,
                    token,
                }) } />
                : <IntentForm {...restProps } setEmail={setEmail} email={email} />
            }
        </Box>
    </Box>
}

RegisterForm.propTypes = {
    addEventListener: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
}

RegisterForm.defaultProps = {
    addEventListener: window.addEventListener,
}

export default RegisterForm
