import { defineMessages } from 'react-intl'

export default defineMessages({
    placeholder: {
        defaultMessage: 'Contraseña',
    },
    passwordError: {
        defaultMessage: 'La contraseña debe tener como mínimo 8 caracteres, una mayúscula, una minúscula, un número y un carácter especial',
    },
    requiredError: {
        defaultMessage: 'Obligatorio',
    },
    lengthError: {
        defaultMessage: 'Debe tener como mínimo 6 caracteres',
    },
})
