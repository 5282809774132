import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { LocalForm } from 'commons/js/components/form'
import { Flex, Box, Copy, PasswordValidators } from '@elparking/components'
import { FormattedMessage } from 'react-intl'
import LoaderButton from 'commons/js/ui/LoaderButton'
import { TRADENAME } from 'commons/js/constants'
import FirstNameField from 'commons/js/ui/FirstNameField'
import LastNameField from 'commons/js/ui/LastNameField'
import FieldGroup from 'commons/js/ui/FieldGroup'
import { PasswordStrictField } from '../PasswordField'

const DataForm = ({
    onSubmit,
    continueMessage,
    loading,
    renderReCaptcha,
    errors,
}) => {
    const [ password, setPassword ] = useState({ value: '', valid: false })
    return <LocalForm
      model='registration'
      onUpdate={(form) => {
        setPassword(form.password)
      }}
      onSubmit={({
        lastName,
        firstName,
        password,
      }) => {
        onSubmit({
            lastName,
            firstName,
            password,
            country: 'es',
            acceptConditions: true,
        })
      }}
    >
        <Flex
          flexWrap='wrap'
          justifyContent='space-between'
          flexDirection='column'>
            <Box w='100%'>
                <Box mb='16px'>
                    <Copy padding='0' size='xxLarge' fontWeight='medium'>
                        <FormattedMessage
                          defaultMessage='Completa tus datos'
                        />
                    </Copy>
                    <Copy padding='0.5em 0 1em 0' size='medium' fontWeight='regular'>
                        <FormattedMessage
                          defaultMessage='Completa tus datos para finalizar tu registro en {tradename} y realizar la reserva. Podrás consultar y gestionar tu reserva desde la app con tu email y contraseña.'
                          values={{ tradename: TRADENAME }}
                        />
                    </Copy>
                    {errors.length > 0 && <Box mb='8px' w='100%' background='error_light'>
                        <Copy padding='1em' color='error' size='small' fontWeight='regular'>
                            <FormattedMessage
                              defaultMessage='Se ha producido un error. Inténtalo más tarde'
                            />
                        </Copy>
                    </Box>}
                </Box>
                <FieldGroup disabled={loading}>
                    <Box w='100%' flex='1 0 100%' mb='1.5em'>
                        <FirstNameField dataTest='register-first-name-field' />
                    </Box>
                    <Box w='100%' flex='1 0 100%' mb='1.5em'>
                        <LastNameField dataTest='register-last-name-field' />
                    </Box>
                    <Box w='100%' flex='1 0 100%' mb='2em'>
                        <PasswordStrictField
                          dataTest='register-password-field'
                          autoComplete='new-password'
                        />
                        <PasswordValidators password={password.value || ''} setIsValidPassword={(valid) => password.valid && valid}/>
                    </Box>
                </FieldGroup>
            </Box>
            <Box w='100%'>
                <LoaderButton dataTest='register-submit-button'
                  formNoValidate
                  loading={loading}
                  disabled={errors.length > 0}
                  model='registration'
                  width='100%'>
                    {continueMessage || <FormattedMessage defaultMessage='Finalizar' />}
                </LoaderButton>
            </Box>
            <Box>
                { renderReCaptcha() }
            </Box>
        </Flex>
    </LocalForm>
}

DataForm.propTypes = {
    renderReCaptcha: PropTypes.func,
    onSubmit: PropTypes.func,
    continueMessage: PropTypes.node,
    loading: PropTypes.bool,
    errors: PropTypes.any,
}

DataForm.defaultProps = {
    errors: [],
    loading: false,
    continueMessage: null,
    onSubmit: () => {},
    renderReCaptcha: () => {},
}

export default DataForm
