import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Control } from 'commons/js/ui/Input'
import { password, required } from 'commons/js/util/validators'
import messages from './messages'
import { PasswordValidators } from '@elparking/components'

const PasswordStrictField = ({ intl, initialValue, onChange, ...restProps }) => {
    const [pass, setPass] = useState(initialValue)
    return <>
        <Control
          model='.password'
          type='password'
          initialValue={initialValue}
          onChange={(value, object) => {
                onChange && onChange(value, object)
                setPass(value)
            }}
          validators={{
                required: required(),
                password: password(),
            }}
          placeholder={intl.formatMessage(messages.placeholder)}
          errorMessages={{
                required: intl.formatMessage(messages.requiredError),
                password: intl.formatMessage(messages.passwordError),
            }}
          debounce={250}
          {...restProps}
            />
        <PasswordValidators
          padding='6px'
          fontSize='xxxSmall'
          fontWeight='book'
          password={pass || ''}
          setIsValidPassword={() => {}} />
    </>
}

PasswordStrictField.propTypes = {
    intl: PropTypes.object,
    onChange: PropTypes.func,
    initialValue: PropTypes.any,
}

export default injectIntl(PasswordStrictField)
