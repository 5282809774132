import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'

import { Copy } from '@elparking/components'
import { Control } from 'commons/js/ui/Checkbox'

import { checked } from 'commons/js/util/validators'

import messages from './messages'

const TermsField = ({ intl, disabled, fontSize, renderMessage, renderTermsLink, dataTest, ...restProps }) => (
    <Control
      model='.acceptConditions'
      validators={{
          checked: checked(),
      }}
      errorMessages={{
          checked: intl.formatMessage(messages.requiredError),
      }}
      justifyContent='start'
      data-test={dataTest}
      label={
          <Copy padding='0' fontWeight='regular' size={fontSize} fontColor={disabled ? 'lightGray' : 'darkGray'}>
              { renderMessage ? renderMessage(renderTermsLink()) : <FormattedMessage
                defaultMessage='He leído y acepto los {termsAndConditions}'
                values={{
                    termsAndConditions: renderTermsLink(),
                }}
              />}
          </Copy>
      }
      disabled={disabled}
      {...restProps}
    />
)

TermsField.propTypes = {
    intl: PropTypes.object,
    renderTermsLink: PropTypes.func,
    dataTest: PropTypes.string,
    disabled: PropTypes.bool,
    renderMessage: PropTypes.func,
    fontSize: PropTypes.any,
}

TermsField.defaultProps = {
    disabled: false,
    fontSize: 'small',
    renderTermsLink: () => null,
}

export default injectIntl(TermsField)
